import React from "react";
import { graphql } from "gatsby";

import Layout from "../components/global/Layout";
import ProductBanner from "../components/global/product/ProductBanner";
import ProductMain from "../components/global/product/ProductMain";
import ProductBlack from "../components/global/product/ProductBlack";
import ProductEasyAccess from "../components/global/product/ProductEasyaccess";
import ProductBlackSteps from "../components/global/product/ProductBlackSteps";
import Testimonials from "../components/global/Testimonials";
import Factsheet from "../components/global/Factsheet";
import GetInTouch from "../components/global/forms/GetInTouch";
import Faqs from "../components/global/Faqs";
import { parseSeoFromQuery } from "../components/global/SEO";
import { useLocale } from "../utils/use-country-location";
import ProductGreenSteps from "../components/global/product/ProductGreenSteps";

export default function Product(props) {
  // Not ordered as in CMS! =/
  const locale = useLocale();
  const data = props?.data?.markdownRemark?.frontmatter;
  const questions = props?.data?.allMarkdownRemark?.edges.map(edge => ({
    ...edge?.node?.frontmatter,
  }));

  return (
    <Layout {...parseSeoFromQuery(props.data)}>
      <ProductBanner {...data?.productBanner} />
      <ProductMain {...data?.productMain} />
      <ProductBlack {...data?.productBlack} />
      <ProductEasyAccess {...data?.productEasyAccess} />
      {data?.productGreenSteps != null && <ProductGreenSteps {...data?.productGreenSteps}/>}
      <ProductBlackSteps {...data?.productBlackSteps} />
      <Factsheet {...data?.factSheet} />
      <Testimonials {...data?.testimonials} />
      <GetInTouch {...data?.getInTouch} />
      <Faqs questions={questions} {...data?.faqs} locale={locale} />
    </Layout>
  );
}

export const query = graphql`
  query ($page: String, $locale: String) {
    markdownRemark(
      frontmatter: { page: { eq: $page }, locale: { eq: $locale } }
    ) {
      frontmatter {
        ...seo
        ...getInTouch
        productBanner {
          title
          headerOne
          headerTwo
        }
        productMain {
          mainTextOne
          mainTextTwo
        }
        productBlack {
          title
          titleGreen
          mainText
          secondMainText
          secondMainTextGreen
          secondMainTextTwo
        }
        productEasyAccess {
          titleGreen
          title
          arrowText
          mainText
        }
        productGreenSteps {
            mainTitle
            title1
            title2
            title3
            subtitle1
            subtitle2
            subtitle3
            description1
            description2
            description3
            summary
        }
        productBlackSteps {
          title
          steps {
            text
            color
          }
          link {
            text
            route
          }
          scrollingIconsText
          icons {
            icon
          }
        }
        factSheet {
          title
          submitButtonText
          additionalText
          document
          hubspotFormUrl
        }
        testimonials {
          title
          quotes {
            quote
            role
            industry
            location
          }
        }
        faqs {
          title
          toFaqButtonText
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { page: { eq: "question" }, locale: { eq: $locale } }
      }
      sort: { order: ASC, fields: frontmatter___order }
    ) {
      edges {
        node {
          frontmatter {
            draft
            order
            question
            answer
          }
        }
      }
    }
  }
`;
